<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-dialog v-model="dialog" width="1200" scrollable persistent>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Edit Inspection and Acceptance Report
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 1200px">
              <v-form @submit.prevent ref="Formref">
                <v-row class="mt-4">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      v-model="poData.InvoiceNo"
                      :rules="[formRules.required]"
                      label="Invoice No."
                      color="header"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-menu
                      dense
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :return-value.sync="poData.DateInvoice"
                      transition="scale-transition"
                      offset-y
                      class="mb-4"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="poData.DateInvoice"
                          :rules="[formRules.required]"
                          label="Invoice Date"
                          readonly
                          v-bind="attrs"
                          multiple
                          chips
                          small-chips
                          color="header"
                          v-on="on"
                        >
                          <template slot="selection" slot-scope="data">
                            <v-chip @click="remove(data.item, 'invoice')">
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </template>
                      <v-date-picker
                        v-model="poData.DateInvoice"
                        multiple
                        no-title
                        scrollable
                        color="header"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu1.save(poData.DateInvoice)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="mt-n4">
                  <v-col cols="12">
                    <v-menu
                      dense
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      :return-value.sync="poData.DateDelivered"
                      transition="scale-transition"
                      offset-y
                      class=""
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="poData.DateDelivered"
                          label="Date Delivered"
                          :rules="[formRules.required]"
                          readonly
                          v-bind="attrs"
                          multiple
                          chips
                          small-chips
                          color="header"
                          v-on="on"
                        >
                          <template slot="selection" slot-scope="data">
                            <v-chip @click="remove(data.item, 'delivered')">
                              {{ data.item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </template>
                      <v-date-picker
                        v-model="poData.DateDelivered"
                        no-title
                        multiple
                        scrollable
                        color="header"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu4 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu4.save(poData.DateDelivered)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="mt-n4">
                  <v-col cols="12" sm="6">
                    <div class="font-weight-bold font-size-18">Inspection</div>
                    <v-divider class="mb-5"></v-divider>
                    <v-checkbox
                      v-model="poData.IsInspected"
                      color="green darken-2"
                    >
                      <template v-slot:label>
                        <div>Inspected</div>
                      </template>
                    </v-checkbox>
                    <v-menu
                      dense
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="poData.DateInspectedStart"
                      transition="scale-transition"
                      offset-y
                      class="mb-4"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="poData.DateInspectedStart"
                          :rules="[formRules.required]"
                          label="Date Inspected (Start)"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="header"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="poData.DateInspectedStart"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(poData.DateInspectedStart)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <br />
                    <v-menu
                      dense
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="poData.DateInspectedEnd"
                      transition="scale-transition"
                      offset-y
                      class="mb-4"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="poData.DateInspectedEnd"
                          :rules="[formRules.required]"
                          label="Date Inspected (End)"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="header"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="poData.DateInspectedEnd"
                        no-title
                        scrollable
                        color="header"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu3 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu3.save(poData.DateInspectedEnd)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <div class="font-weight-bold font-size-18">Acceptance</div>
                    <v-divider class="mb-5"></v-divider>
                    <v-radio-group row v-model="poData.Status">
                      <v-radio
                        label="Complete Delivery"
                        color="green darken-2"
                        value="Complete"
                      ></v-radio>

                      <v-radio
                        label="Partial Delivery"
                        color="green darken-2"
                        value="Partial"
                      ></v-radio>
                    </v-radio-group>
                    <v-menu
                      dense
                      ref="menu5"
                      v-model="menu5"
                      :close-on-content-click="false"
                      :return-value.sync="poData.DateReceivedStart"
                      transition="scale-transition"
                      offset-y
                      class="mb-10"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="poData.DateReceivedStart"
                          :rules="[formRules.required]"
                          label="Date Received (Start)"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="header"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="poData.DateReceivedStart"
                        no-title
                        scrollable
                        color="header"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu5 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu5.save(poData.DateReceivedStart)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <br />
                    <v-menu
                      dense
                      ref="menu6"
                      v-model="menu6"
                      :close-on-content-click="false"
                      :return-value.sync="poData.DateReceivedEnd"
                      transition="scale-transition"
                      offset-y
                      class="mb-10"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="poData.DateReceivedEnd"
                          :rules="[formRules.required]"
                          label="Date Received (End)"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="header"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="poData.DateReceivedEnd"
                        no-title
                        scrollable
                        color="header"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu6 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu6.save(poData.DateReceivedEnd)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="poData.RecieverUserID"
                      item-text="name"
                      item-value="UserID"
                      :rules="[formRules.required]"
                      :items="users"
                      dense
                      class=""
                      label="Select Receiver"
                      color="header"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers2"
                      :items="itemList"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <span class="green--text text--darken-2"
                            >List Of Items</span
                          >
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.StockPropertyNo`]="{ item }">
                        <v-edit-dialog
                          :return-value.sync="item.StockPropertyNo"
                          large
                        >
                          {{ item.StockPropertyNo }}
                          <template v-slot:input>
                            <v-textarea v-model="item.StockPropertyNo">
                            </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.ItemDescription`]="{ item }">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.ItemDescription"
                        >
                          {{ item.ItemDescription }}
                          <template v-slot:input>
                            <v-textarea v-model="item.ItemDescription">
                            </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.UnitCost`]="{ item }">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.UnitCost"
                          v-if="item.isIssued == 0"
                        >
                        <span class="text-no-wrap">  ₱ {{ formatPrice(item.UnitCost) }}</span>
                          <template v-slot:input>
                            <v-textarea v-model="item.UnitCost">
                            </v-textarea>
                          </template>
                        </v-edit-dialog>
                        <span class="text-no-wrap" v-else>  ₱ {{ formatPrice(item.UnitCost) }}</span>
                      </template>
                      <template v-slot:[`item.Qty_waived`]="{ item }">
                          {{ formatQty(item.Qty_waived) }}
                      </template>
                      <template v-slot:[`item.Qty`]="{ item }">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.Qty"
                          v-if="item.isIssued == 0"
                        >
                          <span
                            :class="
                              parseFloat(item.Qty_allowable) <
                                parseFloat(item.Qty) ||
                              parseFloat(item.Qty) <= 0
                                ? 'red--text'
                                : 'green--text'
                            "
                          >
                            {{ item.Qty }}
                          </span>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.Qty"
                              type="number"
                              :rules="[parseFloat(item.Qty_allowable) >=
                                parseFloat(item.Qty),
                              parseFloat(item.Qty) > 0]"
                            >
                            </v-text-field>
                          </template>
                        </v-edit-dialog>
                        <span
                            v-else
                            :class="
                              parseFloat(item.Qty_allowable) <
                                parseFloat(item.Qty) ||
                              parseFloat(item.Qty) <= 0
                                ? 'red--text'
                                : 'green--text'
                            "
                          >
                            {{ item.Qty }}
                          </span>
                      </template>
                      <template v-slot:[`item.Amount`]="{ item }">
                        <span class="text-no-wrap">
                          ₱ {{
                            formatPrice(
                              parseFloat(item.UnitCost) *
                                parseFloat(item.Qty)
                            )
                          }}
                        </span>
                      </template>
                      <template v-slot:[`item.SubItem`]="{ item }">
                        <span class="text-no-wrap">
                          <v-btn color="green darken-2" class="white--text" v-if="item.isIssued == 0" x-small @click="subItems(item, 'edit')">Items({{item.SubItems.length}})</v-btn>
                          <v-btn color="green darken-2" disabled class="white--text" v-else x-small>Items({{item.SubItems.length}})</v-btn>
                        </span>
                      </template>
                      <template v-slot:[`item.Action`]="{ item, index }">
                        <span class="text-no-wrap">
                          <v-btn color="red darken-2" class="white--text" v-if="itemList.length > 1 && item.isIssued == 0" x-small @click="removeItem(item, index)">delete</v-btn>
                          <v-btn color="red darken-2" v-else class="white--text" :disabled="true" x-small>delete</v-btn>
                        </span>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" v-if="itemList_deleted.length > 0">
                    <v-data-table
                      :headers="headers2"
                      :items="itemList_deleted"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <span class="red--text text--darken-2"
                            >List Of Deleted Items</span
                          >
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.UnitCost`]="{ item }">
                        <span class="text-no-wrap">  ₱ {{ formatPrice(item.UnitCost) }}</span>
                      </template>
                      <template v-slot:[`item.Amount`]="{ item }">
                        <span class="text-no-wrap">
                          ₱ {{
                            formatPrice(
                              parseFloat(item.UnitCost) *
                                parseFloat(item.Qty)
                            )
                          }}
                        </span>
                      </template>
                      <template v-slot:[`item.Action`]="{ item, index }">
                        <span class="text-no-wrap">
                          <v-btn color="red darken-2" outlined x-small dark @click="revertItem(item, index)">revert</v-btn>
                        </span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-2" dark @click="updateIAR()">
              <v-icon>mdi-rename-box-outline</v-icon>
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <v-toolbar max-height="65" dark color="green darken-2">
          <v-toolbar-title> {{ poData.IARCODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >Inspection and Acceptance Report</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>Davao del Norte State College</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Fund Cluster:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.FundCluster }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Supplier:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.CompanyName }}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">IAR No.:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.IARCODE }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">PO No./ Date:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader
                  >{{ poData.POCode }} / {{ poData.poDate }}</v-subheader
                >
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Date Created:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.iarDate }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold"
                  >Requisitioning Office/ Dept.:</v-subheader
                >
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.OfficeDescription }}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Invoice No.:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.InvoiceNo }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold"
                  >Respinsibility Center Code:</v-subheader
                >
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.mopDescription }}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Invoice Date:</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{
                  poData.DateInvoice ? poData.DateInvoice.join(", ") : ""
                }}</v-subheader>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                :headers="headers"
                :items="itemList"
                item-key="IARItemID"
                class="elevation-2 mt-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="amber darken-2"
                      dark
                      outlined
                      elevation="2"
                      small
                      class="mr-2"
                      @click="ris()"
                      v-if="!poData.IsIssued && ($store.state.user.roleID == 6 || $store.state.user.roleID == 16)"
                    >
                    <v-icon>mdi-file-check-outline</v-icon>
                      RIS
                    </v-btn>
                    <v-btn
                      outlined
                      color="grey darken-2 mr-2"
                      dark
                      small
                      @click="editiar()"
                      v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 15"
                      class="mr-2"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                      Edit IAR
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="viewIAR(poData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 15) && itemList.length > 0 && poData.IsIssued == 0"
                      @click="unpublish(poData.IARID)"
                    >
                      Unpublish
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
                </template>
                <template v-slot:[`item.Qty_waived`]="{ item }">
                  {{ formatQty(item.Qty_waived) }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.SubItem`]="{ item }">
                  <span class="text-no-wrap">
                    <v-btn color="green darken-2" class="white--text" v-if="item.isIssued == 0 || item.SubItems.length > 0" small @click="subItems(item, 'view')">Items({{item.SubItems.length}})</v-btn>
                    <v-btn color="green darken-2" disabled class="white--text" v-else small>Items({{item.SubItems.length}})</v-btn>
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogIARVersion" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Please select IAR version:</v-card-title>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple</v-icon
              ><br />
              <v-btn color="green darken-2" @click="oldIAR()" text
                >Old version</v-btn
              >
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple-outline</v-icon
              ><br />
              <v-btn color="green darken-2" @click="newIAR()" text
                >New version</v-btn
              >
            </v-col>
            <v-col align="end" cols="12">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogIARVersion = false"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmUnpublish" max-width="500px">
      <v-card>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <v-icon dense x-large color="red darken-2" class="mt-4"
                >mdi-help-circle-outline</v-icon
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title class="text-h6 d-flex justify-center"
          >Do you want to Unpublish this IAR?</v-card-title
        >
        <v-card-actions class="mt-4">
          <v-row justify="center">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-2"
                text
                @click="dialogConfirmUnpublish = false"
                >Cancel</v-btn
              >
            </v-col>
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="green darken-2" text @click="confirmUnpublish()"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmUpdate" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <v-card-text>
            <div>
              Are you sure you want to UPDATE this IAR?
            </div>
          <div v-if="itemList_deleted.length > 0">
            <br />
            <i>
            <b>NOTE:</b> All removed items will be permanently deleted and irreversible.
            </i>
          </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogConfirmUpdate = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="confirmUpdateIAR()"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <v-dialog v-model="dialogAddSubItems" persistent scrollable width="1000">
        <v-card>
          <v-card-title>
            <span class="text-h5 font-weight-bold">Add New Sub item</span>
            <v-spacer></v-spacer>
            <span class="text-subtitle-2 mr-5 ma-0 pa-0">
            <b>Amount:</b> ₱ {{formatPrice(subItemData.Amount)}} <br />
            <b>Balance:</b> ₱ {{formatPrice(availableBalance)}}
            </span>
            <v-btn color="grey darken-2" icon @click="closeAddSubItems()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 900px">
            <v-container class="mt-3">
            <v-form @submit.prevent ref="FormrefSubItems">
            <v-row>
              <v-col cols="12" class="DarkWhite" v-if="subAction == 'edit'">
                <v-row no-gutters>
                  <v-col cols="12" md="9">
                  <v-autocomplete
                    v-model="subItem.ItemID"
                    color="green"
                    item-text="ItemName"
                    item-value="ItemID"
                    :rules="[formRules.required]"
                    :items="items"
                    :loading="itemsisLoading"
                    :search-input.sync="search"
                    dense
                    outlined
                    label="Item"
                    class="ma-1"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for an Item
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="subItem.Qty"
                    color="green"
                    label="Quantity"
                    class="ma-1"
                    :rules="[checkQtyandBalance(), formRules.noNegativeNumberAllowed, formRules.numberRequired]"
                  >
                  </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="green darken-2" block class="white--text" @click="addSubItem()">
                    <v-icon class="mr-1
                    ">mdi-plus-box</v-icon>
                    Add Item
                  </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-data-table
                    :headers="subAction == 'edit' ? headers3 : headers4"
                    :items="subItemData.SubItems"
                    class="elevation-2"
                  >
                    <template v-slot:top>
                        <v-toolbar flat>
                          <span class="green--text text--darken-2">List Of Items</span>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.StockPropertyNo`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.StockPropertyNo" large v-if="subAction == 'edit'">
                        <span>{{ item.StockPropertyNo }}</span>
                        <template v-slot:input>
                          <v-text-field v-model="item.StockPropertyNo" type="text"> </v-text-field>
                        </template>
                      </v-edit-dialog>
                      <span v-else>{{ item.StockPropertyNo }}</span>
                    </template>
                    <template v-slot:[`item.ItemDescription`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.ItemDescription" large  v-if="subAction == 'edit'">
                        <span>{{ item.ItemDescription }}</span>
                        <template v-slot:input>
                          <v-textarea v-model="item.ItemDescription"> </v-textarea>
                        </template>
                      </v-edit-dialog>
                        <span v-else>{{ item.ItemDescription }}</span>
                    </template>
                    <template v-slot:[`item.UnitCost`]="{ item }">
                      <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                    </template>
                    <template v-slot:[`item.Qty`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.Qty" large  v-if="subAction == 'edit'">
                        <span :style="item.Qty <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.Qty) }}</span>
                        <template v-slot:input>
                          <v-text-field v-model="item.Qty" type="number" :min="1" :rules="[item.Qty > 0]"> </v-text-field>
                        </template>
                      </v-edit-dialog>
                       <span v-else :style="item.Qty <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.Qty) }}</span>
                    </template>
                    <template v-slot:[`item.Amount`]="{ item }">
                      <span class="text-no-wrap">₱ {{ formatPrice(item.Qty * item.UnitCost) }}</span>
                    </template>
                    <template v-slot:[`item.Action`]="{ item, index }">
                        <span class="text-no-wrap">
                          <v-btn color="red darken-2" x-small dark @click="removeSubItem(item, index)">Delete</v-btn>
                        </span>
                      </template>
                  </v-data-table>
              </v-col>
              <v-col cols="12" v-if="subItems_deleted.length > 0">
                <v-data-table
                    :headers="headers3"
                    :items="subItems_deleted"
                    class="elevation-2"
                  >
                  <template v-slot:top>
                      <v-toolbar flat>
                        <span class="red--text text--darken-2">List Of Deleted Items</span>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                  </template>
                    <template v-slot:[`item.UnitCost`]="{ item }">
                      <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                    </template>
                    <template v-slot:[`item.Qty`]="{ item }">
                      {{ formatQty(item.Qty) }}
                    </template>
                    <template v-slot:[`item.Amount`]="{ item }">
                      <span class="text-no-wrap">₱ {{ formatPrice(item.Qty * item.UnitCost) }}</span>
                    </template>
                    <template v-slot:[`item.Action`]="{ item, index }">
                        <span class="text-no-wrap">
                          <v-btn color="red darken-2" v-if="(parseFloat(item.UnitCost) * parseFloat(item.Qty)) <= availableBalance" outlined x-small dark @click="revertSubItem(item, index)">revert</v-btn>
                          <v-btn color="red darken-2" v-else disabled outlined x-small>revert</v-btn>
                        </span>
                      </template>
                  </v-data-table>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions v-if="subAction == 'edit'">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="closeAddSubItems()">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" class="white--text" @click="saveSubItem()" v-if="parseFloat(formatPrice(availableBalance)) == 0">
              <v-icon>mdi-check-circle</v-icon>
              Save items
            </v-btn>
            <v-btn color="green darken-2" class="white--text" v-else disabled>
              <v-icon>mdi-check-circle</v-icon>
              Save items
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <CreateRISModal :risData="risData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  components: {
    CreateRISModal: () => import("../Supply/CreateRIS.vue"),
  },
  props: {
    poData: {},
  },
  data: () => ({
    pardialog: false,
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    itemList: [],
    itemList_filtered: [],
    itemList_deleted: [],
    dialogIARVersion: false,
    link: "",
    formData: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
        align: "center"
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Qty (PO)", value: "OverAllQty", sortable: false, align: "center" },
      { text: "Qty (Waived)", value: "Qty_waived", sortable: false, align: "center" },
      { text: "Qty", value: "Qty", sortable: false, align: "center" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "SubItems", value: "SubItem", sortable: false, align: "center" },
    ],
    headers2: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
        align: "center"
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Qty (PO)", value: "OverAllQty", sortable: false, align: "center" },
      { text: "Qty (Waived)", value: "Qty_waived", sortable: false, align: "center" },
      { text: "Qty (Allowable)", value: "Qty_allowable", sortable: false, align: "center" },
      { text: "Qty (Editable)", value: "Qty", sortable: false, align: "center" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "SubItems", value: "SubItem", sortable: false, align: "center" },
      { text: "Action", value: "Action", sortable: false, align: "center" },
    ],
    headers3: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
        align: "center"
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Qty", value: "Qty", sortable: false, align: "center" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "Action", value: "Action", sortable: false, align: "center" },
    ],
    headers4: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
        align: "center"
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Qty", value: "Qty", sortable: false, align: "center" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
    ],
    dialogConfirmUnpublish: false,
    toUnpublishIARID: null,
    unpublishBtn: false,
    risData: [],
    dialogConfirmUpdate: false,
    subItem: [],
    dialogAddSubItems: false,
    items: [],
    itemsisLoading: false,
    search: null,
    subItemData: [],
    subItemData_temp: [],
    subItems_deleted: [],
    availableBalance: 0,
    subAction: '',
  }),
  watch: {
    poData: {
      handler(data) {
        if (data.IARID) {
          this.getItems();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
    search(val) {
      this.itemsisLoading = true;
      setTimeout(() => {
        if (val == this.search) {
          this.searchItem();
        }
      }, 2000);
    },
    subItemData: {
      handler(data) {
        let balance = parseFloat(data.Amount);
        let subItems = data.SubItems || [];
        subItems.forEach(item => {
          balance = balance - parseFloat(parseFloat(item.UnitCost).toFixed(2) * item.Qty);
        });
        this.availableBalance = balance;
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeCreateRIS");
  },
  beforeDestroy() {
    this.eventHub.$off("closeCreateRIS");
  },
  methods: {
    editiar() {
      this.dialog = !this.dialog;
    },
    iar() {
      this.PurposeDialog = true;
    },
    viewIAR(item) {
      this.dialogIARVersion = true;
      this.link = this.apiUrl + "/iar/report/" + item.IARID;
    },
    oldIAR() {
      window.open(this.link + "/old");
      this.dialogIARVersion = false;
    },
    newIAR() {
      window.open(this.link + "/new");
      this.dialogIARVersion = false;
    },
    ris(){
      this.risData = this.poData
    },
    clickCloseMainDialogPR() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewIAR", false);
    },

    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      let data = new FormData();
      data.append("IARID", this.poData.IARID);
      this.axiosCall("/iar/getItems", "POST", data).then((res) => {
        this.itemList_filtered = [];
        this.itemList = res.data.data.result;
        this.itemList.forEach(item => {
          if(item.isUsed == 0){
            this.itemList_filtered.push(item);
          }
        });
        this.users = res.data.data.users;
        this.ableToUnpublish();
      });
    },
    updateIAR(){
        let error = false;
        for (let item of this.itemList) {
          if (
            parseFloat(item.Qty_allowable) < parseFloat(item.Qty) ||
            parseFloat(item.Qty) <= 0
          ) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
        } else {
        if (this.$refs.Formref.validate()) {
        this.dialogConfirmUpdate = true;
        }
        }
    },
    confirmUpdateIAR() {
      if (this.$refs.Formref.validate()) {
        this.isLoading = true;
        let error = false;
        for (let item of this.itemList) {
          if (
            parseFloat(item.Qty_allowable) < parseFloat(item.Qty) ||
            parseFloat(item.Qty) <= 0
          ) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
        } else {
          let data = new FormData();
          data.append("List", JSON.stringify(this.itemList));
          data.append("List_deleted", JSON.stringify(this.itemList_deleted));
          data.append("IARID", this.poData.IARID);
          data.append("InvoiceNo", this.poData.InvoiceNo);
          data.append(
            "DateInvoice",
            JSON.stringify({ ...this.poData.DateInvoice })
          );
          data.append("DateInspectedStart", this.poData.DateInspectedStart);
          data.append("DateInspectedEnd", this.poData.DateInspectedEnd);
          data.append("RecieverUserID", this.poData.RecieverUserID);
          data.append(
            "DateDelivered",
            JSON.stringify({ ...this.poData.DateDelivered })
          );
          data.append("DateReceivedStart", this.poData.DateReceivedStart);
          data.append("DateReceivedEnd", this.poData.DateReceivedEnd);
          data.append("Status", this.poData.Status);
          data.append("IsInspected", this.poData.IsInspected == true ? 1 : 0);
          data.append("SupplierName", null);
          this.axiosCall("/iar/update", "POST", data).then((res) => {
            this.isLoading = false;
            if (res.data.status) {
              //this.$refs.Formref.reset();
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "success";
              this.fadeAwayMessage.message = "Item Successfully Updated!";
              this.fadeAwayMessage.header = "System Message";
              this.dialogConfirmUpdate = false;
              this.dialog = false;
            } else {
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = "System Message";
            }
          });
        }
      }
    },
    ableToUnpublish() {
      // this.unpublishBtn = false;
      //   this.itemList.forEach(item => {
      //     if (item.POID != 0 && item.PRstatus != 4) {
      //     this.unpublishBtn = true;
      //   }
      //   });
      return true;
    },
    unpublish(IARID) {
      this.dialogConfirmUnpublish = true;
      this.toUnpublishIARID = IARID;
    },
    confirmUnpublish() {
      let data = new FormData();
      data.append("IARID", this.toUnpublishIARID);
      data.append("List", JSON.stringify(this.itemList));
      this.axiosCall("/iar/unpublish", "POST", data).then((res) => {
        if (res.data.status) {
          this.toUnpublishPOID = null;
          this.dialogConfirmUnpublish = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "PO successfully unpublished!";
          this.fadeAwayMessage.header = "System Message";
          this.getItems();
        } else {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = res.data.message;
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    remove(date, field) {
      let data = [];
      if (field == "invoice") {
        data = this.poData.DateInvoice;
      } else if (field == "delivered") {
        data = this.poData.DateDelivered;
      }
      var index = data.findIndex((x) => x === date);
      data.splice(index, 1);
    },
    removeItem(item, index){
      this.itemList.splice(index, 1);
      this.itemList_deleted.push(item)
    },
    revertItem(item,index){
      this.itemList_deleted.splice(index, 1);
      this.itemList.push(item)
    },
    subItems(data, action){
      this.subAction = action;
      this.subItemData = data;
      this.subItemData_temp = JSON.stringify(data);
      this.subItem = {
        ItemID: null,
        Qty: null
      };
      let balance = parseFloat(this.subItemData.Amount);
      let subItems = this.subItemData.SubItems || [];
      subItems.forEach(item => {
        balance = balance - parseFloat(parseFloat(item.UnitCost).toFixed(2) * item.Qty);
      });
      this.availableBalance = balance;
      this.dialogAddSubItems = true;
    },
    searchItem() {
      let data = new FormData();
      data.append("search", this.search);
      data.append("CategoryTypeID", 2);
      this.axiosCall("/search/new/item", "POST", data).then((res) => {
        this.items = res.data.data.result;
        this.itemsisLoading = false;
        this.$forceUpdate();
      });
    },
    checkQtyandBalance(){
      let unitCost = 0;
      let balance = parseFloat(this.subItemData.Amount);
      this.items.forEach(item => {
        if(item.ItemID == this.subItem.ItemID){
          unitCost = item.UnitCost
        }
      });
      let subItems = this.subItemData.SubItems || [];
      subItems.forEach(item => {
        balance = balance - parseFloat(parseFloat(item.UnitCost).toFixed(2) * item.Qty);
      });
      let amount = this.subItem.Qty * unitCost;
      return amount <= balance || "Insufficient Balance!"
    },
    addSubItem(){
      if (this.$refs.FormrefSubItems.validate()) {
      let data = null;
      this.items.forEach(item => {
        if(item.ItemID == this.subItem.ItemID){
          data = item
        }
      });
      if(data != null){
        let exists = false;
        this.subItemData.SubItems.forEach(item => {
          if(data.ItemID == item.ItemID && data.UnitCost == item.UnitCost){
            item.Qty = parseFloat(item.Qty) + parseFloat(this.subItem.Qty);
            exists = true;
          }
        });
        if(!exists){
        let code = '';
        if(data.ClassificationID != null){
          if(data.ClassificationType == 1){
            code = this.poData.FundCode + '-' + data.Code + '-' + data.FirstLetter + '-' + data.Stock;
          }
          else if(data.ClassificationType == 2){
            code = this.poData.FundCode + '-' + data.Code + '-' + data.FirstLetter + '-' + data.Property;
          }
        }
        this.subItemData.SubItems.push({...data, Qty: this.subItem.Qty, StockPropertyNo: code, IARItemID: this.subItemData.IARItemID});
        }
        this.subItem = {
          ItemID: null,
          Qty: null
        };
        this.items = [];
        let balance = parseFloat(this.subItemData.Amount);
        let subItems = this.subItemData.SubItems || [];
        subItems.forEach(item => {
          balance = balance - parseFloat(parseFloat(item.UnitCost).toFixed(2) * item.Qty);
        });
        this.availableBalance = balance;
      }
      }
    },
    removeSubItem(item, index){
      this.subItemData.SubItems.splice(index, 1);
      this.subItems_deleted.push(item)
    },
    revertSubItem(item,index){
      this.subItems_deleted.splice(index, 1);
      this.subItemData.SubItems.push(item)
    },
    saveSubItem(){
      this.subItemData = [];
      this.subItems_deleted = [];
      this.subItem = {
        ItemID: null,
        Qty: null
      };
      this.items = [];
      this.dialogAddSubItems = false;
    },
    closeAddSubItems(){
      let data = JSON.parse(this.subItemData_temp);
      this.itemList.forEach(item => {
        if(item.IARItemID == data.IARItemID){
          item.SubItems = data.SubItems;
        }
      });
      this.subItemData = [];
      this.subItems_deleted = [];
      this.subItem = {
        ItemID: null,
        Qty: null
      };
      this.items = [];
      this.dialogAddSubItems = false;
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>